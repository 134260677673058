<template>
  <div class="add-party">
    <div class="party-container">
      <!-- 步骤条 -->
      <div class="steps">
        <Steps :current="steps">
          <Step title="基本信息" :status="status[0]"></Step>
          <Step title="党务信息" :status="status[1]"></Step>
        </Steps>
      </div>
      <!-- 内容 -->
      <div class="container">
        <!-- 左侧菜单 -->
        <Row class="left-list">
          <Menu theme="light" :active-name="steps" @on-select="selectMenu">
            <MenuItem :name="item.id" v-for="item in menuList" :key="item.id">{{
              item.name
            }}</MenuItem>
          </Menu>
        </Row>

        <!-- 右侧表单 -->
        <div class="right-content">
          <keep-alive>
            <BasicInfo
              @nextstep="nextstep"
              v-if="toggleMenu == 0"
              :formValidate="formValidate"
              @stepError="stepError"
              ref="form"
              @replaceFormData="replaceFormData"
              :replaceFormSign="replaceFormSign"
            />
            <PartyInfo
              @nextstep="nextstep"
              v-else
              :partyForm="partyForm"
              @submit="submit"
            />
          </keep-alive>
        </div>
      </div>
    </div>
    <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import BasicInfo from "./basicInfo.vue";
import PartyInfo from "./partyInfo";
export default {
    props: ['partyId',"addChangeSign"],
  data() {
    return {
      //replaceFormSign
      replaceFormSign: false,
      spinShow: false,
      //steps状态
      status: ['process', 'wait'],
      //左侧菜单与右侧步骤条
      steps: 0,
      //左侧菜单栏标识切换右侧组件
      toggleMenu: 0,
      menuList: [
        {
          id: 0,
          name: "基本信息",
        },
        {
          id: 1,
          name: "党务信息",
        },
      ],
      //保存验证
      submitValidate: [
        {
          name: 'userCode',
          value: '请输入档案编号！'
        },
        {
          name: 'projectCode',
          value: '请选择所属社区！'
        },
        {
          name: 'name',
          value: '请输入姓名！'
        },
        {
          name: 'sex',
          value: '请选择性别！'
        },
        {
          name: 'idNum',
          value: '请输入身份证号码！'
        },
        {
          name: 'account',
          value: '请输入手机号码！'
        },
      ],
      //右侧基本信息
      formValidate: {
        userCode: "",
        //所属社区
        projectCode: "",
        name: "",
        //性别
        sex: "",
        //年月
        birthday: "",
        //民族
        national: "",
        //籍贯
        hometown: "",
        //身份证号
        idNum: "",
        eduDegree: "",
        account: "",
        //所在单位
        workUnit: "",
        //职业分类
        skill: "",
        // 单位职务
        post: "",
      },
      //党务信息表单数据
      partyForm: {
        //所在党支部
        cbranch: "",
        //党内职务
        communistPost: "",
        //入党时间
        joinDate: "",
        //单位党组织联系电话
        unitTel: "",
        //进入支部类型
        joinType: "",
        //转离支部类型
        leaveType: "",
        //是否加入党员服务队
        joinService: "",
        //能参加社区服务时间
        serviceTime: "",
        //服务意向
        serviceIntention: "",
      },
    };
  },
  methods: {
    //身份证号码或手机号码覆盖
    replaceFormData(data) {
      for (let k in data) {
        if (this.formValidate.hasOwnProperty(k)) {
          this.formValidate[k] = data[k];
        } else {
          this.partyForm[k] = data[k];
        }
      }
      this.replaceFormSign = true;
    },
    //Steps状态修改
    stepError(status) {
       this.status.splice(0,1,status);
    },
    //下一步
    nextstep(n) {
      this.steps += n;
      this.status[this.steps] = 'process';
      this.selectMenu(this.steps);
      return this.steps;
    },
    //左侧菜单
    selectMenu(name) {
      this.steps = name;
      this.toggleMenu = name;
      this.$emit('selectMenu',name)
    },
    // 提交验证
    submitValFun(arr) {
      for(let k in arr) {
        if(k == arr.length -1 && this.formValidate[arr[k].name] != '') return true;
         if(!this.formValidate[arr[k].name]) {
             this.$Message.error({
                background: true,
                content: arr[k].value,
              });
            return false;
          }
      }
    },
    //点击提交
    submit() {
      if(!this.submitValFun(this.submitValidate)) return;
      if (this.partyForm.joinDate) {
        let time = new Date(this.partyForm.joinDate);
        this.partyForm.joinDate =
          time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
      }
      let obj = Object.assign({}, this.formValidate, this.partyForm);
      obj.oemCode = parent.vue.oemInfo.oemCode;
      if(obj.birthday) {
        let birthday = new Date(obj.birthday);
        obj.birthday =  birthday.getFullYear() + "-" + (birthday.getMonth() + 1) + "-" + birthday.getDate();
      }
      if (this.addChangeSign && !this.replaceFormSign) {
        //新增党员
        this.$post("/syaa/pc/sy/user/communist/saveCommunist", obj).then(
          (res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "新增成功",
              });
               this.$emit("pageChange",{
                  page: 1,
                  pageSize: 20
              })
               this.$emit("addChangeModalFn",false);
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          }
        );
      } else {
        //修改党员
        this.$post("/syaa/pc/sy/user/communist/updateCommunsit", obj).then(
          (res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "修改成功",
              });
              this.$emit("pageChange",{
                  page: 1,
                  pageSize: 20
              })
               this.$emit("addChangeModalFn",false);
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          }
        );
      }
    },
  },
  components: {
    BasicInfo,
    PartyInfo,
  },
  created() {
    //判断是否为党员修改页面
    if (!this.addChangeSign) {
      this.spinShow = true;
      this.replaceFormSign = true;
     
      this.$get("/syaa/pc/sy/user/communist/selectByUserId", this.partyId).then(
        (res) => {
          if (res.code == 200) {
            if (res.data["birthday"]) {
              let time = new Date(res.data["birthday"]);
              res.data["birthday"] =
                time.getFullYear() +
                "-" +
                (time.getMonth() + 1) +
                "-" +
                time.getDate();
            }
            if (res.data.sex == "男") {
              res.data.sex = "1";
            } else if (res.data.sex == "女") {
              res.data.sex = "2";
            }
            for (let k in res.data) {
              if (this.formValidate.hasOwnProperty(k)) {
                this.formValidate[k] = res.data[k];
              } else {
                this.partyForm[k] = res.data[k];
              }
            }
            this.spinShow = false;
          }
        }
      );
    }
  },
};
</script>
    
<style scoped lang='less'>
ul {
  list-style: none;
}
.add-party {
  background: #fcfcfc;
  .steps {
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .ivu-steps-status-process,
    .ivu-steps-status-finish {
      /deep/.ivu-steps-head-inner {
        border-color: #12b26d;
        background-color: #12b26d;
      }
    }
    /deep/.ivu-steps-icon {
      color: #fff;
    }
    /deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
      background: #12b26d;
    }
    /deep/.ivu-steps-status-error .ivu-steps-icon {
      color: #ed4014;
    }
  }
  .ivu-steps {
    margin: 20px;
    width: 30%;
    text-align: left;
  }
  .party-container {
    padding: 20px 30px;
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .ivu-menu {
      background: #fff;
    }
    .ivu-menu-item {
      color: #303133;
      background: #fff;
    }
    .ivu-menu-item:hover {
      color: #409eff;
    }
    .ivu-menu-item-selected.ivu-menu-item-active {
      background: #fff;
      color: #409eff;
    }
    .left-list {
      height: 100%;
      width: 220px;
    }
    /deep/.ivu-menu {
      width: 220px !important;
      background: #fcfcfc;
      li {
        background: #fcfcfc;
      }
      li,
      .ivu-menu-item-selected:hover,
      li:hover {
        color: #12b26d;
      }
      .ivu-menu-item-selected {
        color: #12b26d;
      }
    }
    .ivu-menu-vertical.ivu-menu-light:after {
      width: 2px;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after {
      background: #12b26d;
    }
    .right-content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      background: #fff;
      height: calc(100vh - 263px);
      padding: 20px;
    }
  }
}
</style>